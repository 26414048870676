<template>
  <!-- 推荐品牌 -->
  <div class="container">
    <div class="putitle">
      <span
        :class="
          datas.position == 1
            ? 'left'
            : datas.position == 3
            ? 'right'
            : ''
        "
        >{{ $i18n.locale == "en" ? datas.titleen : datas.title }}</span
      >
    </div>
    <div class="rebrand pucontainer">
      <div class="bitem" v-for="(item, i) in datas.item" :key="i">
        <router-link :to="{path: '/goodslist', query:{params: getUrl(item.guid)}}" class="img">
          {{ item.title }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import _ from 'lodash'
export default {
  name: 'recommonBrand',
  props: {
    datas: {
      type: Object
    }
  },
  computed: {
    getUrl () {
      return function (guid) {
        const params = _.cloneDeep(store.state.goodsModule.originalParams)
        params.htype = store.state.pageType.id
        params.generalattributeid = JSON.stringify([{ 品牌: [guid] }])
        return encodeURI(JSON.stringify(params))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin: 50px auto;
}
.rebrand {
  max-width: 1380px;
  margin: 0 auto;
  text-align: left;
  .bitem {
    height: 120px;
    display: inline-block;
    width: 25%;
    padding: 10px 0;
    vertical-align: top;
    .img {
      width: 100%;
      height: 100px;
      border-right: 1px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 30px;
      text-align: center;
      padding: 0 20px;
      line-height: 1;
      white-space: nowrap;
    }
  }
}
.rebrand .bitem:nth-of-type(1),
.rebrand .bitem:nth-of-type(2) {
  width: 50%;
}
.rebrand .bitem:nth-of-type(2) .img,
.rebrand .bitem:last-child .img {
  border-right: none;
}
.rebrand .bitem:nth-of-type(4n-2) .img {
  border-right: none;
}
</style>
